<template>
  <div>
    <div v-if="expired">Link Expired. Please contact to manager.</div>
    <div v-else class="pub_page">
      <!-- header section start -->
      <b-navbar variant="light" class="fixed-top" toggleable="md">
        <b-navbar-brand href="#">
          <img
            src="../../assets/images/webpatrol-logo-2.png"
            alt=""
            style="height: 2rem"
          />
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse is-nav id="nav-collapse">
          <b-navbar-nav class="ml-auto" v-if="login">
            <b-nav class="navbar-right">
              <b-nav-item @click="scrollToTarget('#service')"
                ><span class="header_url">Services</span></b-nav-item
              >
              <b-nav-item class="ml-2" @click="scrollToTarget('#media')"
                ><span class="header_url">Media</span></b-nav-item
              >
              <b-nav-item class="ml-2" @click="scrollToTarget('#urllist')"
                ><span class="header_url">Url List</span></b-nav-item
              >
              <b-nav-item class="ml-2" @click="scrollToTarget('#hosts')"
                ><span class="header_url">Hosts</span></b-nav-item
              >
              <b-nav-item class="ml-2" @click="scrollToTarget('#logs')"
                ><span class="header_url">Logs</span></b-nav-item
              >
              <b-nav-item class="ml-2" @click="scrollToTarget('#comments')"
                ><span class="header_url">Comments</span></b-nav-item
              >
              <b-nav-item
                class="ml-2"
                href="https://scienlabs.com/#contactus"
                target="blank"
                ><span class="header_url">Contact</span></b-nav-item
              >
              <b-nav-item class="ml-2"
                ><span class="header_url" @click="Logout()" style="color: red"
                  >Logout</span
                ></b-nav-item
              >
            </b-nav>
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto" v-else>
            <b-nav>
              <b-nav-item href="#" class="ml-2" v-if="!login"
                ><span class="header_url" @click="LoginModal = true"
                  >LOGIN</span
                ></b-nav-item
              >
            </b-nav>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      <!-- header section end -->
      <br />

      <!-- Service Section start -->
      <div class="col-md-12 d-flex justify-content-center mt-5" id="service">
        <div class="custom_box col-md-10 f-18 p-2 p-md-5">
          <b-row class="fixed_div">
            <b-col class="col-md-6 col-12">
              <b-row class="box-width gap-between">
                <span class="text-muted col-md-5 col-12">Service Provider</span>
                <span class="col-12 col-md-6">
                  {{ mainObject.spName }}
                </span>
              </b-row>
              <b-row class="mt-2 gap-between">
                <span class="text-muted col-md-4 col-12">Channel</span>
                <span class="col-12 col-md-6">{{
                  mainObject.channel_value
                }}</span>
              </b-row>
              <b-row class="mt-2 gap-between">
                <span class="text-muted col-md-4 col-12">Payment type</span>
                <span class="col-12 col-md-6">{{
                  mainObject.paymentInfo
                }}</span>
              </b-row>
              <b-row class="mt-2 gap-between">
                <span class="text-muted col-md-4 col-12">Device</span>
                <span class="col-12 col-md-6"> - </span>
              </b-row>
              <b-row class="mt-2 gap-between">
                <span class="text-muted col-md-4 col-12">Network-OP</span>
                <span class="col-12 col-md-6">
                  {{ mainObject.netType_value }}
                </span>
              </b-row>
              <b-row class="mt-2 gap-between" v-if="mainObject.country">
                <span class="text-muted col-md-4 col-12">Country</span>
                <span class="col-12 col-md-6"> {{ mainObject.country }} </span>
              </b-row>
            </b-col>
            <b-col class="col-md-6 col-12">
              <b-row class="mt-2 gap-between">
                <span class="text-muted col-md-4 col-12">Service Name</span>
                <span class="col-12 col-md-6">{{ mainObject.sName }}</span>
              </b-row>
              <b-row class="mt-2 gap-between">
                <span class="text-muted col-md-4 col-12">Category</span>
                <span class="col-12 col-md-6">{{
                  mainObject.category_value
                }}</span>
              </b-row>
              <b-row class="mt-2 gap-between">
                <span class="text-muted col-md-4 col-12">Banner URL</span>
                <a
                  class="cust_url col-12 col-md-6"
                  :href="mainObject.bannerFoundUrl"
                  target="blank"
                  >{{ mainObject.bannerFoundUrl }}</a
                >
              </b-row>
              <b-row class="mt-2 gap-between">
                <span class="text-muted col-md-4 col-12">Landing URL</span>
                <a
                  class="cust_url col-12 col-md-6"
                  :href="mainObject.finalUrl"
                  target="blank"
                  >{{ mainObject.finalUrl }}</a
                >
              </b-row>
              <b-row class="mt-2 gap-between">
                <span class="text-muted col-md-4 col-12">Created Date</span>
                <span class="col-12 col-md-6">{{
                  changeDateFormate(mainObject.createdAt)
                }}</span>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>

      <!-- Service Section end  -->

      <!-- Media section start -->
      <div class="col-md-12 d-flex justify-content-center mt-5" id="media">
        <div class="custom_box col-md-10 f-18 p-2 p-md-5">
          <b-row>
           
            <b-col cols=4>
              <div>
                <div class="f-18 f-w-600">Home Page</div>
                <b-img
                   thumbnail
                  fluid
                  class="img_custom_style"
                  style="max-height: 410px;"
                  :src="mainObject.bannerImgUrl"
                  alt="Banner"
                  @click="openImageModal(mainObject._id, 'banner')"
                  @error="$event.target.src = `../no_image.png`"
                ></b-img>
              </div>
            </b-col>
            <b-col cols=4>
              <div>
                <div class="f-18 f-w-600">Advertisement</div>
                <b-img
                  thumbnail
                  fluid
                  class="img_custom_style"
                  style="max-height: 410px;"
                  :src="mainObject.adImgUrl"
                  alt="Ad"
                  @click="openImageModal(mainObject._id, 'ad')"
                  @error="$event.target.src = `../no_image.png`"
                ></b-img>
              </div>
            </b-col>
          </b-row>

          <b-row class="mt-5">
            <b-col
              v-for="(image, index) in mainObject.landingImgUrl"
              :key="index"
            >
              <div class="f-18 f-w-600">Landings</div>
              <div class="landing_img_container">
                <img
                  :src="image"
                  thumbnail
                  fluid
                  class="img_custom_style"
                  style="max-height: 410px;"
                  @click="openImageModal(mainObject._id, 'landing', index)"
                  @error="$event.target.src = `../no_image.png`"
                />
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- Media section end -->

      <!-- URL list section start -->
      <div
        class="col-md-12 d-flex justify-content-center mt-5 mb-5"
        id="urllist"
      >
        <div
          class="custom_box col-md-10 f-18 copycontent p-2 p-md-5"
          @click="CopyShareUrl(mainObject.urlChain)"
        >
          <b-row>
            <h4><label>URL list</label></h4>
            <b-col class="text-right"
              ><i class="icofont icofont-copy-black ml-3 ico-hover"></i
            ></b-col>
          </b-row>
          <b-row class="mt-2 url_box">
            <li
              class="col-md-12 mt-2"
              v-for="item in mainObject.urlChain"
              :key="item.key"
            >
              <span class="protocol f-w-500 text-info">
                {{ item }}
              </span>
            </li>
          </b-row>
        </div>
      </div>
      <!-- URL list section end -->

      <!-- Host section start -->
      <div class="col-md-12 d-flex justify-content-center mt-5 mb-5" id="hosts">
        <div class="custom_box col-md-10 f-18 p-2 p-md-5">
          <h4>Hosts</h4>
          <b-row class="mt-2">
            <b-card-text>
              <li v-for="item in mainObject.hosts" :key="item.key">
                {{ item }}
              </li>
            </b-card-text>
          </b-row>
        </div>
      </div>
      <!-- Host section end -->

      <!-- Logs section start -->
      <div class="col-md-12 d-flex justify-content-center mt-5 mb-5" id="logs">
        <div class="custom_box col-md-10 f-18 p-2 p-md-5">
          <h4>Logs</h4>
          <b-row class="mt-2">
            <b-card-text>
              <li v-for="item in mainObject.logs" :key="item.key">
                {{ item }}
              </li>
            </b-card-text>
          </b-row>
        </div>
      </div>
      <!-- Logs section end -->

      <!-- Comments section start -->
      <div
        class="col-md-12 d-flex justify-content-center mt-5 mb-5"
        id="comments"
      >
        <div class="custom_box col-md-10 f-18 p-2 p-md-5">
          <h4>Comments</h4>
          <div class="col-md-3" v-if="load_commnet">
            <div class="loader-box">
              <div class="loader-15"></div>
            </div>
          </div>
          <div v-else>
            <div
              class="comment-item"
              v-for="comment in commentList"
              :key="comment._id"
            >
              <div class="comment-text">{{ comment.comment }}</div>
              <div class="comment-meta">
                <span class="text-muted">Commented by </span>
                <span class="text-primary" v-if="comment.role === 'manager'">
                  Manager
                </span>
                <span class="text-primary" v-else>
                  {{ comment.username ? comment.username : comment.useremail }}
                </span>
              </div>
              <div class="mt-2">
                <button
                  v-if="
                    (comment.useremail === userinfo.email ||
                      userinfo.role === 'manager') &&
                    login
                  "
                  class="btn btn-outline-info btn-xs mr-2"
                  @click="toggleDetails(comment)"
                >
                  {{ comment.detailsShowing ? "Cancel" : "Edit" }}
                </button>
                <button
                  class="btn btn-outline-danger btn-xs mr-2"
                  v-if="
                    (comment.useremail === userinfo.email ||
                      userinfo.role === 'manager') &&
                    login
                  "
                  @click="DeleteComment(comment)"
                >
                  Delete
                </button>
              </div>
              <div v-if="comment.detailsShowing">
                <b-card bg-variant="light" class="mt-3">
                  <b-form-textarea
                    class="textarea-custom"
                    id="commnets"
                    placeholder="Edit Comment..."
                    rows="2"
                    max-rows="5"
                    v-model="comment.comment"
                  ></b-form-textarea>
                  <b-button
                    size="sm"
                    @click="editComment(comment)"
                    variant="info"
                    class="mt-2"
                  >
                    Save
                  </b-button>
                </b-card>
              </div>
            </div>
          </div>

          <!-- Add new comment -->
          <div class="mt-3" v-if="login">
            <b-form-textarea
              class="col-md-12 textarea-custom"
              id="commnets"
              placeholder="Write a comment..."
              rows="3"
              max-rows="6"
              v-model="comment_text"
            ></b-form-textarea>
            <b-button
              variant="success"
              class="mt-2"
              @click.prevent="AddCommnet(mainObject._id)"
            >
              Add Comment
            </b-button>
          </div>

          <!-- Prompt to login -->
          <div v-else>
            <span
              class="text-danger"
              @click="LoginModal = true"
              style="cursor: pointer"
            >
              Login now to comment...
            </span>
          </div>
        </div>
      </div>
      <!-- Comments section end -->

      <footer v-if="!login">
        <b-row>
          <b-col class="text-center f-20" style="cursor: pointer">
            <div class="f-w-700 text-white" @click="LoginModal = true">
              PLEASE LOGIN FOR FULL ACCESS
            </div>
            <div class="f-w-900 text-white" v-if="login" @click="Logout()">
              LOGOUT
            </div>
          </b-col>
        </b-row>
      </footer>

      <!-- login modal start -->
      <b-modal v-model="LoginModal" title="Login" ok-only hide-footer>
        <div class="card-body p-5 text-left">
          <form action="" @submit.prevent="Login()">
            <div class="form-outline mb-4">
              <label class="form-label" for="typeEmailX-2"
                >Enter Your Email</label
              >
              <input
                v-model="input.email"
                type="email"
                id="typeEmailX-2"
                class="form-control form-control-lg"
                placeholder="e.g info@example.com"
              />
            </div>

            <div class="form-outline mb-4">
              <label class="form-label" for="typePasswordX-2"
                >Enter Your Password</label
              >
              <b-input-group>
                <b-form-input
                  v-model="input.password"
                  v-on:keyup.enter="Login()"
                  type="password"
                  id="newpassword"
                  class="form-control form-control-lg"
                  placeholder="**********"
                />
              </b-input-group>
            </div>

            <button
              class="btn btn-lg btn-block text-white"
              type="submit"
              style="background-color: blue"
            >
              Sign in
            </button>
          </form>
          <hr class="my-4" />
        </div>
      </b-modal>
      <!-- login modal end -->
    </div>
  </div>
</template>

<script>
import PublicTranscationService from "../../../services/publicServices";
import TranscationService from "../../../services/TranscationService";
import LoginController from "../../../services/LoginControlService";
import config from "../../../services/globalLit";
import Userauth from "../../auth/js/index";
import moment from "moment";

export default {
  data() {
    return {
      expired: false,
      comment_fields: ["_id", "show_details"],
      currentComment: [],
      commentList: [],
      mainObject: {
        adDetailsTable: [],
      },

      showModal: "",

      tableKeys: {
        adUrl: "Ad URL",
        bannerFoundUrl: "Banner Found URL",
        finalUrl: "Final URL",
        netType: "Network|OP", //1: wifi 2: op1 3: op2
        channel: "Channel",

        paymentInfo: "Payment Info",
        pricePointInfo: "Pricepoint Info",
        createdAt: "Date",
      },
      fields: ["key", "value"],
      adDetailsTable: [{ key: "action", value: "action" }],
      modalimageurl: "",
      show_all_commnets: false,
      comment_text: "",
      public_service_url: "",
      userinfo: { email: "", role: "" },
      load_commnet: false,
      trx_id: "",
      login: false,
      LoginModal: false,
      input: {},
    };
  },
  mounted() {
    this.checkLogin();
    this.trx_id = this.$route.params.id;
    this.init();
  },
  beforeDestroy() {
    console.log("distroyed openTrx");
    this.$root.$off("openTrx");
  },
  methods: {
    changeDateFormate(d) {
      return moment.utc(d).format("D/M/YYYY, HH:mm A");
    },
    getFormattedUrl(url) {
      if (config.isValidUrl(url)) {
        try {
          var urlObject = new URL(url);
          var protocol = urlObject.protocol;
          var domain = urlObject.hostname;
          var path = urlObject.pathname;
          var params = urlObject.search;
          let rurl = {
            protocol: protocol ? protocol : "",
            domain: domain ? domain : "",
            path: path ? path : "",
            params: params ? params : "",
          };
          return rurl;
        } catch (error) {
          console.error("Invalid URL:", url, error);
          return null;
        }
      }
    },
    async editComment(data) {
      this.load_commnet = true;
      let payload = { cid: data._id, updateObj: { comment: data.comment } };
      let response = await TranscationService.editComment(payload);
      this.getComments(data.trxid);
      let variant = response.result ? "success" : "error";
      this.$toasted.show(response.message, {
        theme: "outline",
        position: "bottom-center",
        type: variant,
        duration: 2000,
      });
      this.load_commnet = false;
    },
    async DeleteComment(data) {
      this.load_commnet = true;
      var payload = { cid: data._id };
      if (confirm("Are you sure you want to delete this comment?")) {
        let response = await TranscationService.deleteComment(payload);
        let variant = response.result ? "success" : "error";
        this.$toasted.show(response.message, {
          theme: "outline",
          position: "bottom-center",
          type: variant,
          duration: 2000,
        });
        this.getComments(data.trxid);
      }
      this.load_commnet = false;
    },
    async getComments(id) {
      this.load_commnet = true;
      let response = await PublicTranscationService.getComment(id);
      if (response.result) {
        this.commentList = response.data;
      }
      this.load_commnet = false;
    },
    async AddCommnet(id) {
      this.load_commnet = true;
      if (this.comment_text == "") {
        this.$toasted.show("Please enter comment", {
          theme: "outline",
          position: "bottom-center",
          type: "error",
          duration: 2000,
        });
        return;
      }
      var payload = { trxid: id, comment: this.comment_text };
      let response = await TranscationService.addCommnet(payload);
      this.getComments(id);
      let variant = response.result ? "success" : "error";
      this.$toasted.show(response.message, {
        theme: "outline",
        position: "bottom-center",
        type: variant,
        duration: 2000,
      });
      this.comment_text = "";
      this.load_commnet = false;
    },
    CopyShareUrl(urls) {
      config.CopyShareUrl(urls);
      this.$toasted.show("Copied", {
        theme: "outline",
        position: "bottom-center",
        type: "success",
        duration: 2000,
      });
    },
    async ExportTrx(id) {
      let url = config.publicexportTrnx + `${id}`;
      const a = document.createElement("a");
      a.href = url;
      a.download = "transaction.pdf";
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    openImageModal(data, type, index) {
      this.modalimageurl = "";

      switch (type) {
        case "banner":
          this.modalimageurl = this.getImageBanner(data);
          break;
        case "ad":
          this.modalimageurl = this.getImageAdd(data);
          break;
        case "landing":
          this.modalimageurl = this.getImageLanding(data, index);
          break;
      }
      // window.open('', '_blank', `width=${screen.width},height=${screen.height}`).document.write(`<html><head><title>Image Popup</title></head><body><img src="${this.modalimageurl}" style="max-width: 100%; height: auto;"></body></html>`);
      const popupWindow = window.open(
        "",
        "_blank",
        `width=${screen.width},height=${screen.height}`
      );
      popupWindow.document.write(
        `<html><head><title>Image Popup</title></head><body><img src="${this.modalimageurl}" style="max-width: 100%; height: auto;"></body></html>`
      );
      popupWindow.document.addEventListener("keydown", function (event) {
        if (event.key === "Escape" || event.keyCode === 27) {
          popupWindow.close();
        }
      });
    },
    getImageLanding(id, index) {
      return config.publicgetLandingImages + `/${id}.png?index=${index}`;
    },
    getImageBanner(id) {
      return config.publicgetBannerImages + `/${id}.png`;
    },
    getImageAdd(id) {
      return config.publicgetAddImages + `/${id}.png`;
    },
    arrayBufferToBase64(buffer) {
      var binary = "";
      var bytes = new Uint8Array(buffer);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return binary;
    },
    async init() {
      console.log("init call");
      let options = {
        trx_id: this.trx_id,
        time: this.$route.query.time,
        hash: this.$route.query.hash,
      };
      let response = await PublicTranscationService.getTrxDetail(options);
      if (response.result) {
        this.mainObject = response.data;
        this.mainObject.adDetailsTable = [];

        this.mainObject.category =
          config.categorylist[this.mainObject.category].name;

        let trxKey = Object.keys(this.mainObject);

        for (let i = 0; i < trxKey.length; i++) {
          if (this.tableKeys[trxKey[i]]) {
            let rec = {
              key: this.tableKeys[trxKey[i]],
              value: this.mainObject[trxKey[i]],
            };
            if (rec.key == "Channel") {
              rec.value = config.channelTypeArray.find(
                (e) => e.id == rec.value
              ).name;
            }

            this.mainObject.adDetailsTable.push(rec);
          }
        }
        this.getComments(this.mainObject._id);
      } else {
        this.$toasted.show(response.message, {
          theme: "outline",
          position: "bottom-center",
          type: "error",
          duration: 2000,
        });
        this.expired = true;
      }
    },
    toggleDetails(row) {
      if (row.detailsShowing) {
        row.toggleDetails();
      } else {
        this.currentComment.forEach((item) => {
          this.$set(item, "_showDetails", false);
        });
        this.$nextTick(() => {
          row.toggleDetails();
        });
      }
    },
    checkLogin() {
      if (Userauth.isAuthenticated() && Userauth.checAlive()) {
        this.userinfo = JSON.parse(window.localStorage.getItem("userinfo"));
        this.login = true;
        this.LoginModal = false;
      }
    },
    async Login() {
      // this.$router.replace("/");
      this.loading = true;
      let response = await LoginController.login(this.input);
      if (response.result) {
        if (response.message.isactive) {
          Userauth.localLogin(response.message);
          this.$toasted.show("Successfully Logged In", {
            theme: "outline",
            position: "bottom-center",
            type: "success",
            duration: 2000,
          });
          this.checkLogin();
        } else {
          this.$toasted.show(
            "Your Account is not Activated. Please contact to manager",
            {
              theme: "outline",
              position: "bottom-center",
              type: "error",
              duration: 4000,
            }
          );
        }
      } else {
        this.$toasted.show(response.message, {
          theme: "outline",
          position: "bottom-center",
          type: "error",
          duration: 2000,
        });
        // Userauth.Logout();
      }
      this.loading = false;
    },
    Logout() {
      if (confirm("Are you sure you want to logout?")) {
        Userauth.Logout();
        this.login = false;
      }
    },
    scrollToTarget(id) {
      const element = document.querySelector(id);
      const offset = 60;
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = element.getBoundingClientRect().top;
      const elementPosition = elementRect - bodyRect;
      const offsetPosition = elementPosition - offset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.hidden_header {
  display: none;
}
div {
  margin: auto 0;
  width: 100%;
}

.label-text {
  font-weight: 600;
  color: #6c757d; /* Muted text color */
  display: block;
  min-width: 120px;
}

.value-text {
  display: block;
  font-weight: 500;
  color: #343a40;
}

.text-muted {
  font-weight: 600;
  color: #6c757d;
}

.cust_url {
  color: #007bff;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  /* max-width: 200px; Adjust width as needed */
  /* vertical-align: middle; */
  transition: color 0.2s;
}

.cust_url:hover {
  color: #0056b3;
  text-decoration: underline;
}

.custom_box {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
  word-wrap: break-word;
}

.custom_box h4 {
  font-size: 22px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
}

.custom_box ul {
  padding-left: 20px;
  margin-top: 10px;
}

.custom_box li {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
  border-bottom: 1px solid #f0f0f0;
  padding: 5px 0;
}

/* .custom_box li:last-child {
  border-bottom: none;
} */

.custom_box li::marker {
  color: #007bff;
}

.custom_box li:hover {
  background: #f8f9fa;
  cursor: default;
  transition: background 0.3s ease;
}

.cus_hw {
  max-height: 80vh;
  min-height: 40vh;
  max-width: 20rem;
  min-width: 20rem;
}
@media screen and (max-width: 576px) {
  /* apply styles for screens smaller than 576px */
  .cus_hw {
    max-width: 40vh;
    min-width: 20vh;
    max-height: none;
    min-height: none;
  }
}
.pub_page {
  background: #021132;
  padding-bottom: 20px;
}

.navbar-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
}

.header_url {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  padding: 10px;
  margin-left: 5px;
  margin-right: 5px;
}
.header_url:hover {
  color: #001f6e;
  text-decoration: none;
  font-size: 17px;
  font-weight: bold;
  transition: all;
}

.box-width {
  width: 100%;
}

.gap-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

footer {
  background: #19a5d1d1;
  padding: 10px;
  position: sticky;
  bottom: 0;
}
.ico-hover {
  display: none;
}
.copycontent:hover .ico-hover {
  display: block;
}
.copycontent:hover {
  cursor: copy;
}

.fixed_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
}


.img_custom_style {
  max-width: 80%; /* Slightly smaller for landing images */
  max-height: 220px;
  object-fit: cover;
  cursor: pointer;
  transition: transform 0.2s;
  border-radius: 10px;
}

.img_custom_style:hover {
  transform: scale(1.01);
}

.comment-item {
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 0;
  margin-bottom: 10px;
}

.comment-item:last-child {
  border-bottom: none;
}

.comment-text {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
}

.comment-meta {
  font-size: 14px;
  color: #888;
}

.comment-meta .text-primary {
  font-weight: bold;
  color: #007bff;
}

.btn-xs {
  font-size: 12px;
  padding: 3px 8px;
}

.textarea-custom {
  border-radius: 5px;
}

.loader-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.loader-15 {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
